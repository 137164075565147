/**  教程 */
<template>
  <v-app class="section-box">
    <v-banner
        single-line
        height="250"
        class="banner"
      >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/shijieCaseBannerH5.png"
          height="100%"
          :transition="false"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1060 mt-10">
          <v-card color="transparent banner-text ml-6" flat>
            <v-card-title class="white--text pa-0 font-size-h5banner-title">
              逐渐成长为专家
            </v-card-title>
            <v-card-title class="white--text pa-0 mt-2 font-size-h5banner-tip">
              选择你所感兴趣的内容进<br/>行学习吧
            </v-card-title>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    
    <v-container class="main-box width-per-100">
      <div class="main-item">
        <v-tabs v-model="tab" centered color="#0084ff" class="g-tabsTwo-h5 mb-4 width-per-100" >
          <v-tab v-for="(item,index) in data" :key="index">
            <v-card-title class="font-size-16 px-2" :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.title }}</v-card-title>
          </v-tab>
        </v-tabs>
        <div class="new-two">
        <v-tabs-items v-model="tab" class="width-per-100">
            <v-tab-item v-for="(item,index) in data" :key="index"  style="margin:0 auto;padding-bottom:12px;width:100%;">
              <div style="margin-top: 6px;">
                <v-row class="pa-0 px-2" style="background: #F5F8FC;">
                  <v-col 
                    :cols='cardCols' 
                    class="d-flex justify-center pa-0 px-2 pb-3"
                    v-for="(info,infoIndex) in item.tabItem" :key="infoIndex"
                  >
                    <v-card flat class="h5-card item-card " width="100%" @click="goCaseDetail(info.id)" style="border-radius:5px;">
                      <v-img max-width="100%" contain :src="info.coverImg"></v-img>
                      <div class="text-box px-3">
                          <v-card-text class="sizeStyle pa-0 mt-3 mb-1 title" v-text="info.itemTitle"></v-card-text>
                          <v-card-text class="sizeStyle pa-0 tip pb-10" v-text="info.itemTip"></v-card-text>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>
        </v-tabs-items>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';
// import qs from 'qs'

export default {
  name: 'CooperationCase',
  data() {
    return {
      data: contentData.developCase,
      tab: null,
      imgLoad: false
    }
  },
  created() {
  },
  computed: {
    cardCols(){
      let colsValue = 6
      if(this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm'){
        colsValue = 6
      } else if(this.$vuetify.breakpoint.name == 'md'){
        colsValue = 4
      }else{
        colsValue = 3
      }
      return colsValue
    }
  },
    methods: {
    goCaseDetail(id) {
      this.$router.push({
        name: 'DevelopExperienceDetail',
        query:{
          id: id,
        }
      })
      console.log(id)
    },
  }

}
</script>

<style lang="scss" scoped>
    .main-item{
      .v-card__subtitle, .v-card__title{
        color: #666666 !important;
      }
    }
    .new-two{
      width: 100%;
      border-radius: 8px;
      transition: 0.3s ease;
      
      // &:hover {
      //   transform: scale(1.04);
      //   box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
      //   // transform:translateY(-16px);
      // }
      .v-window-item{
        background: #f5f8fd;
      }
      .item-card{
        background: #fff;
        box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);
        border-radius: 5px;

        .text-box {
          .title {
            font-size: 14px !important;
            font-weight: 400;
            color: #333333;
            padding: 0 !important;
            margin-left: 3px;
            margin: 16px 0 8px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: start;
            height: unset;
          }
          .tip{
            font-size: 12px;
            color: #999999;
            line-height: 17px;
          }
          &::after {
            content: "";
            background-image: url("../../../../assets/images/home/case-icon.png");
            background-size: 100%;
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: 15px;
            left: 10px;
            padding-right: 5px;
          }
          &:focus {
            display: none !important;
          }
        }
      }
    }

.section-box {
  background: #f5f8fd !important;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  ::v-deep .v-responsive__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.sizeStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>